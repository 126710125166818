.profile__button-edit {
  box-sizing: border-box;
  padding: 0;
  background-image: url(../../../images/button-edit.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  width: 24px;
  height: 24px;
  border: 1px solid #fff;
  grid-area: button_edit;
  align-self: center;
  cursor: pointer;
  transition: opacity .3s linear;
}

.profile__button-edit:hover {
  opacity: .6;
}

@media screen and (max-width: 984px) {
  .profile__button-edit {
    width: 18px;
    height: 18px;
  }
}