.popup__caption {
  margin: 0;
  max-width: 290px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}