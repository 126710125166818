.login__form-input {
  padding: 0;
  border: none;
  outline: none;
  width: 100%;
  height: 27px;
  background-color: transparent;
  border-bottom: 2px solid #CCC;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ccc;
}

.login__form-input:nth-of-type(1) {
  margin: 0 0 30px;
}

.login__form-input:nth-of-type(2) {
  margin-bottom: 216px;
}