.login__button {
  width: 358px;
  height: 50px;
  padding: 0;
  border: none;
  border-radius: 2px;
  background-color: #fff;
  color: #000;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  transition: opacity .3s linear;
}

.login__button:hover {
  opacity: .8;
}