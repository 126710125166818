.login__link {
  margin-left: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #fff;
  transition: opacity .3s linear; 
}

.login__link:hover {
  opacity: .8;
}