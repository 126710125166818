.header__logo {
  width: 142px;
  height: 33px;
}

@media screen and (max-width: 600px) {
  .header__logo {
    width: 103px;
    height: 24px;
  }
}