.card__button-like {
  background-image: url(../../../images/like.svg);
  background-color: transparent;
  min-width: 22px;
  height: 19px;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: opacity .3s linear;
}

.card__button-like:hover {
  opacity: .5;
}