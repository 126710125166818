.menu {
  display: flex;
  align-items: flex-end;
  gap: 24px;
}

@media screen and (max-width: 600px) {
  .menu {
    flex-direction: column;
    align-items: center;
    row-gap: 18px;
  }
}