.popup__title {
  margin: 0 0 47px;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
}

@media screen and (max-width: 600px) {
  .popup__title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 71px;
  }
}
