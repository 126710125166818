.popup__button-close {
  background-image: url(../../../images/popup-close.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
  padding: 0;
  width: 32px;
  height: 32px;
  position: absolute;
  right: -40px;
  top: -40px;
  cursor: pointer;
  transition: opacity .3s linear;
}

.popup__button-close:hover {
  opacity: .6;
}

@media screen and (max-width: 600px) {
  .popup__button-close {
    width: 20px;
    height: 20px;
    background-size: 20px;
    right: 0;
    top: -35px;
  }
}