.profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 30px;
}

@media screen and (max-width: 600px) {
  .profile {
    flex-direction: column;
    align-items: center;
    row-gap: 36px;
  }
}