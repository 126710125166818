.popup__form-input {
  padding: 0;
  border: none;
  outline: none;
  width: 100%;
  height: 27px;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.popup__form-input:nth-of-type(1) {
  margin: 0 0 5px;
}

.popup__form-input:nth-of-type(2) {
  margin-bottom: 5px;
}