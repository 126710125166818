.popup__button-save_disabled {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  opacity: 0.2;
}

.popup__button-save_disabled:hover {
  opacity: 0.2;
  cursor: auto;
}