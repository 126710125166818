.profile__info {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

@media screen and (max-width: 600px) {
  .profile__info {
    flex-direction: column;
    align-items: center;
    row-gap: 26px;
    text-align: center;
  }
}