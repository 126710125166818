.profile__caption {
  max-width: 550px;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  grid-area: profile_caption;
}

@media screen and (max-width: 600px) {
  .profile__caption {
    font-size: 14px;
    line-height: 17px;
  }    
}