.page {
  max-width: 880px;
  margin: 0 auto;
  padding-top: 45px;
  padding-bottom: 60px;
/*   background-color: #000000; */
  font-family: "Inter", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
} 

@media screen and (max-width: 600px) {
  .page {
    padding-top: 28px;
    padding-bottom: 36px;
  }
}