.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  background-color: rgba(0, 0, 0, .5);
  visibility: hidden;
  opacity: 0;
  transition: visibility .2s, opacity .2s linear;
  justify-content: center;
  align-items: center;
}