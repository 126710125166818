.popup__container {
  position: relative;
  width: 430px;
  box-sizing: border-box;
  padding: 34px 36px 37px;
  background: #fff;
  box-shadow: 0 0 25px rgba(0, 0, 0, .15);
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .popup__container {
    width: 282px;
    padding: 25px 22px;
  }
}