.profile__name {
  max-width: 500px;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  font-size: 42px;
  line-height: 48px;
  grid-area: profile_name;
}

@media screen and (max-width: 984px) {
  .profile__name {
    font-size: 27px;
    line-height: 33px;
  }
}